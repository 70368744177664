<template>
  <div id="btnborder">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name:'BtnBorder',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#btnborder{
  height: 40px;
  width: 160px;
  border: 1px solid red;
  border-radius: 20px;
  cursor: pointer;
  line-height: 40px;
}
</style>
