<template>
  <div id="personalservice">
    <div class="title">
      <h1>投資者</h1>
      <h2>簡單 ，輕鬆 ，安全</h2>
      <h3>Finger致力用最簡單且明瞭的投資方式，帶給投資者全新的投資體驗。</h3>
      <div class="btnbox">
        <btn-border @click="goMore">了解更多</btn-border>
      </div>

      <img src="@/assets/img/service/phone2.png" alt="">
    </div>
  </div>
</template>

<script>
import BtnBorder from '@/components/button/BtnBorder.vue'
export default {
  name:'PersonalService',
  components: {
    BtnBorder
  },
  data() {
    return {

    }
  },
  methods:{
    goMore(){
      this.$router.push('/service/personal2')
    }
  },
}
</script>

<style scoped>
.personalservice{
  width: 100vw;
  height: 100vh;
}
.title{
  padding-top: 200px;
  color: aliceblue;
  text-align: center;
  margin-bottom: 40px;
}
h3{
  margin-bottom: 40px;
  margin-top: 40px;
}
h2{
    margin-bottom: 40px;
  margin-top: 40px;
}
.title h1{
  font-size: 80px;
}
.btnbox{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
</style>
